import React, { useContext, useEffect, useState } from "react";
import { IonRouterOutlet, IonLoading } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { getToken, getMessaging } from "firebase/messaging";

import Firebase, { FIREBASE_CONFIG } from "./firebase/firebase";
import { checkAuth, setAuthToken } from "./services/authService";
import { registerAxiosErrorHandlerToAxios } from "./services/httpService";
import { store } from "./store/store";
import { FirebaseContext } from "./firebase";
import {
  collection,
  doc,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
  updateDoc,
  WhereFilterOp,
} from "firebase/firestore";

import Login from "./pages/Login/Login";
import Menu from "./components/Menu/Menu";
import Dashboard from "./pages/Dashboard/Dashboard";
import { Reports } from "./pages/Reports/Reports";
import { Report } from "./pages/Report/Report";
import { Profile } from "./pages/Profile/Profile";
import { Users } from "./pages/Users/Users";
import { Categories } from "./pages/Categories/Categories";
import { Products } from "./pages/Products/Products";
import { ProductLog } from "./pages/ProductLog/ProductLog";
import { Stores } from "./pages/Stores/Stores";
import { Orders } from "./pages/Orders/Orders";
import { NewOrder } from "./pages/NewOrder/NewOrder";
import { Actions } from "./store/actionTypes";
import { OrderDetails } from "./pages/OrderDetails/OrderDetails";
import { Tickets } from "./pages/Tickets/Tickets";
import { Ticket } from "./pages/Ticket/Ticket";
import { Looses } from "./pages/Looses/Looses";
import { OrderLog } from "./pages/OrderLog/OrderLog";
import { Warehouse } from "./pages/Warehouse/Warehouse";
import { ProductsPrices } from "./pages/Reports/ProductPrices/ProductsPrices";
import { ProductsStocks } from "./pages/Reports/ProductStocks/ProductsStocks";

import RouteGuard from "./utils/RouteGuard";
import { useSelector } from "react-redux";
import { UserTypes } from "./models/User";

const history = createBrowserHistory();

const AppRouter = () => {
  const firebase = useContext<Firebase>(FirebaseContext);
  const [appLoading, setAppLoading] = useState(false);
  const user: any = useSelector<any>((state) => state.auth?.user);

  console.log(firebase);

  useEffect(() => {
    checkUserExist();
    registerAxiosErrorHandlerToAxios();
    requestPermission(firebase.app, FIREBASE_CONFIG.vapidKey);

    // OneSignal.init({ appId: "652493ea-b90f-4b4d-9524-cb669a4106bd" }).then(
    //   () => {
    //     setInitialized(true);
    //     OneSignal.showSlidedownPrompt({force: true}).then(() => {
    //       console.log("showSlidedownPrompt")
    //     });

    //     OneSignal.on('subscriptionChange', function (isSubscribed:any) {
    //       console.log("The user's subscription state is now:",isSubscribed);
    //       OneSignal.getUserId(function(userId) {
    //         console.log("OneSignal User ID:", userId);
    //       });
    //       });
    //   }
    // );
  }, []);

  useEffect(() => {
    addUserToFirebase(user);
  }, [user]);

  const queryData = async (
    whereClause: {
      field: string;
      condition: WhereFilterOp;
      value: string;
    } | null = null
  ) => {
    let q;
    if (whereClause) {
      q = query(
        collection(firebase.firestore, "users"),
        where(whereClause.field, whereClause.condition, whereClause.value)
      );
    } else q = query(collection(firebase.firestore, "users"));

    const querySnapshot = await getDocs(q);
    return querySnapshot;
  };

  const addDocument = async (data: {
    id: string;
    name: string;
    token: string;
    type: string;
  }) => {
    const docRef = await addDoc(collection(firebase.firestore, "users"), data);
  };

  const addUserToFirebase = async (user: any) => {
    if (user.name && [UserTypes.admin, UserTypes.manager].includes(user.type)) {
      const data = await queryData({
        field: "id",
        condition: "==",
        value: user.id,
      });
      const users: {
        [key: string]: any;
      } = {};
      data.forEach(async (document) => {
        users[document.id] = document.data();
      });
      const existUser = Object.keys(users).find(
        (key) => users[key].id === user.id
      );
      const pushDeviceId = localStorage.getItem("push_device_id");

      if (!existUser && pushDeviceId) {
        addDocument({
          id: user.id,
          name: user.name,
          token: pushDeviceId,
          type: user.type,
        });
      } else if (pushDeviceId) {
        const userRef = doc(firebase.firestore, "users", existUser!!);
        await updateDoc(userRef, {
          token: pushDeviceId,
        });
      }
    }
  };

  const deleteDocument = async (
    document: any,
    userId: string,
    documentId: string
  ) => {
    if (document.data().id === userId)
      await deleteDoc(doc(firebase.firestore, "users", documentId));
  };

  async function requestPermission(firebaseConfig: any, vapidKey: string) {
    const messaging = getMessaging(firebaseConfig);

    await getToken(messaging, { vapidKey: FIREBASE_CONFIG.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          localStorage.setItem("push_device_id", currentToken);
        } else {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              getToken(messaging, {
                vapidKey,
              }).then((currentToken) => {
                if (currentToken) {
                  localStorage.setItem("push_device_id", currentToken);
                }
              });
            } else {
              console.log("Unable to get permission to notify.");
              localStorage.removeItem("push_device_id");
            }
          });
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        localStorage.removeItem("push_device_id");
        console.log(
          "An error occurred while retrieving notificattion token. ",
          err
        );
      });
  }

  const checkUserExist = async () => {
    setAppLoading(true);

    const user = await checkAuth();

    await new Promise((r) => setTimeout(r, 2000));

    if (!user) {
      setAuthToken(null);
      history.replace("/login");
    } else {
      store.dispatch({
        type: Actions.AUTH.USER_LOGIN,
        payload: user,
      });
    }
    setAppLoading(false);
  };

  return (
    <>
      <IonReactRouter history={history}>
        {/*<IonSplitPane contentId="main">*/}
        <Menu />
        <IonRouterOutlet id="main">
          <Route exact={true} path="/warehouse" component={Warehouse} />
          <Route exact={true} path="/dashboard" component={Dashboard} />
          <Route exact={true} path="/reports" component={Reports} />
          <Route exact={true} path="/reports/:name" component={Report} />
          <Route
            exact={true}
            path="/reports/productsPricesV2"
            component={ProductsPrices}
          />
          <Route
            exact={true}
            path="/reports/productsStocks"
            component={ProductsStocks}
          />
          <Route exact={true} path="/login" component={Login} />
          <RouteGuard exact={true} path="/profile" component={Profile} />
          <RouteGuard exact={true} path="/users" component={Users} />
          <RouteGuard exact={true} path="/products" component={Products} />
          <RouteGuard
            exact={true}
            path="/products/logs/:productId"
            component={ProductLog}
          />
          <RouteGuard exact={true} path="/looses" component={Looses} />
          <RouteGuard exact={true} path="/categories" component={Categories} />
          <Route exact={true} path="/stores" component={Stores} />
          <RouteGuard exact={true} path="/orders" component={Orders} />
          <RouteGuard
            exact={true}
            path="/stores/:storeid?/orders"
            component={Orders}
          />
          <RouteGuard exact={true} path="/tickets" component={Tickets} />
          <RouteGuard exact={true} path="/ticket/:id" component={Ticket} />
          <RouteGuard
            exact={true}
            path="/new-order/:id?"
            component={NewOrder}
          />
          <RouteGuard exact={true} path="/order/:id" component={OrderDetails} />
          <RouteGuard
            exact={true}
            path="/order/logs/:id"
            component={OrderLog}
          />
          <Redirect exact={true} path="/" to="/login" />
        </IonRouterOutlet>
        {/*</IonSplitPane>*/}
      </IonReactRouter>
      <IonLoading isOpen={appLoading} message={"Please wait..."} />
    </>
  );
};

export { history };
export default AppRouter;
