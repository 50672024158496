import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonItemDivider,
  IonLabel,
  IonModal,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import classNames from "classnames";
import React, { useState } from "react";
import Load from "./Load/Load";
import "./LoadShelvingModal.scss";
import { Shelving } from "./Shelving/Shelving";

export const LoadShelvingModal = (props: any) => {
  const [tabType, setTabType] = useState("load");
  const [isOpen, setIsOpen] = useState(props.displayModal);

  const toggleModal = () => {
    props.toggleModal();
  };

  return (
    <IonModal
      isOpen={isOpen}
      swipeToClose={false}
      keyboardClose={false}
      backdropDismiss={false}
      cssClass={"load-shelving-modal"}
    >
      <IonHeader className={classNames("ion-no-border")}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/dashboard" />
          </IonButtons>

          <IonTitle className="ion-title"> Load : Shelving</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-modal-content">
        <IonCard className="ion-card">
          <IonCardHeader className="ion-card-header">
            <IonCardTitle>
              <IonSegment
                className={classNames("ion-segment")}
                value={tabType}
                onIonChange={(e) => setTabType(e.detail.value!)}
              >
                <IonSegmentButton className="ion-segment-button" value="load">
                  <IonLabel>Load</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  className="ion-segment-button"
                  value="shelving"
                >
                  <IonLabel>Shelving</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              <IonItemDivider className="ion-divider"></IonItemDivider>
            </IonCardTitle>
          </IonCardHeader>
          {tabType === "load" ? <Load /> : <Shelving />}
        </IonCard>
      </IonContent>

      {/*<IonHeader translucent>*/}
      {/*  <IonToolbar>*/}
      {/*    <IonTitle>Load and UnLoad:</IonTitle>*/}
      {/*    <IonButtons slot="end">*/}
      {/*      <IonButton*/}
      {/*        className="text-success font-weight-bold"*/}
      {/*        onClick={() => {}}*/}
      {/*      >*/}
      {/*        Save*/}
      {/*      </IonButton>*/}
      {/*    </IonButtons>*/}
      {/*    <IonButtons slot="end">*/}
      {/*      <IonButton onClick={toggleModal}>Close</IonButton>*/}
      {/*    </IonButtons>*/}
      {/*  </IonToolbar>*/}
      {/*</IonHeader>*/}
      {/*<IonContent className="ion-padding">*/}
      {/*  <IonRefresher slot="fixed" onIonRefresh={props.getInitialLocations}>*/}
      {/*    <IonRefresherContent></IonRefresherContent>*/}
      {/*  </IonRefresher>*/}

      {/*  <IonSearchbar*/}
      {/*    className="px-0 mb-2"*/}
      {/*    value={searchedLocation}*/}
      {/*    onIonChange={(e) => setSearchedLocation(e.detail.value!)}*/}
      {/*    debounce={2500}*/}
      {/*    placeholder="Code or A'name':B'name' e.g. aisle named Roy => ARoy"*/}
      {/*  />*/}

      {/*  <IonItem className="p-0 m-0">*/}
      {/*    <IonLabel>Show Only Free Spaces: </IonLabel>*/}
      {/*    <IonToggle*/}
      {/*      checked={isFree}*/}
      {/*      onIonChange={(e) => {*/}
      {/*        setFree((e: any) => (isFree ? false : true));*/}
      {/*        getLocations(0, isFree ? 0 : 1);*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </IonItem>*/}
      {/*  <div style={{ overflowX: "auto" }}>*/}
      {/*    <table className="product-update-table">*/}
      {/*      <thead>*/}
      {/*        <tr className="product-update-form-size-title">*/}
      {/*          <th>*/}
      {/*            <h2>Aisle</h2>*/}
      {/*          </th>*/}
      {/*          <th>*/}
      {/*            <h2>Bay</h2>*/}
      {/*          </th>*/}
      {/*          <th>*/}
      {/*            <h2>Level</h2>*/}
      {/*          </th>*/}
      {/*          <th>*/}
      {/*            <h2>Position</h2>*/}
      {/*          </th>*/}
      {/*          <th>*/}
      {/*            <h2>Active</h2>*/}
      {/*          </th>*/}
      {/*          <th>*/}
      {/*            <h2>Product Title</h2>*/}
      {/*          </th>*/}
      {/*          <th>*/}
      {/*            <h2>Product Code</h2>*/}
      {/*          </th>*/}
      {/*          <th>*/}
      {/*            <h2>Type</h2>*/}
      {/*          </th>*/}
      {/*          <th>*/}
      {/*            <h2>Action</h2>*/}
      {/*          </th>*/}
      {/*        </tr>*/}
      {/*      </thead>*/}
      {/*      <tbody>*/}
      {/*        {(filteredLocations?.length > 0*/}
      {/*          ? filteredLocations*/}
      {/*          : locations*/}
      {/*        )?.map((loc: any) => {*/}
      {/*          return (*/}
      {/*            <tr key={loc.title}>*/}
      {/*              {columns.map((col) => {*/}
      {/*                return (*/}
      {/*                  <td className="font-weight-bold">*/}
      {/*                    {renderValue(loc, col)}*/}
      {/*                  </td>*/}
      {/*                );*/}
      {/*              })}*/}
      {/*              <td*/}
      {/*                className="font-weight-bold action"*/}
      {/*                onClick={() =>*/}
      {/*                  clickHandler(*/}
      {/*                    loc,*/}
      {/*                    loc.sizes?.length > 0 ? "unload" : "load"*/}
      {/*                  )*/}
      {/*                }*/}
      {/*              >*/}
      {/*                {loc.sizes?.length > 0 ? "unload" : "load"}*/}
      {/*              </td>*/}
      {/*            </tr>*/}
      {/*          );*/}
      {/*        })}*/}
      {/*      </tbody>*/}
      {/*    </table>*/}
      {/*  </div>*/}

      {/*  /!* Loading *!/*/}
      {/*  <IonLoading isOpen={loading} message={"Please wait..."} />*/}

      {/*  <IonInfiniteScroll*/}
      {/*    threshold="10px"*/}
      {/*    onIonInfinite={() =>*/}
      {/*      getLocations(meta.current_page + 1, isFree ? 1 : 0, true)*/}
      {/*    }*/}
      {/*    disabled={isInifinityLoadingDisabled}*/}
      {/*  >*/}
      {/*    <IonInfiniteScrollContent*/}
      {/*      loadingSpinner="bubbles"*/}
      {/*      loadingText="Loading more data..."*/}
      {/*    />*/}
      {/*  </IonInfiniteScroll>*/}
      {/*</IonContent>*/}
    </IonModal>
  );
};
