import React, { useEffect, useState } from "react";
import "./ProductLog.scss";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import _ from "lodash";
import moment from "moment";
import { useParams } from "react-router";
import axios from "axios";
import { APIs } from "../../services/apiService";
import { toast } from "react-toastify";
import Layout from "../../components/Layout/Layout";

export const ProductLog: React.FC = () => {
  const { productId } = useParams();

  const [logs, setLogs] = useState<any>([]);
  const [sizesLogs, setSizesLogs] = useState<any>([]);

  const getFirstLetterOfName = (txt: any): string => {
    if (txt) if (txt.length > 0) return txt.substr(0, 1).toUpperCase();

    return "-";
  };

  const getProductLogs = async () => {
    return axios
      .get(APIs.products.logs(productId))
      .then((res) => {
        setLogs(res.data.data);
      })
      .catch((err) =>
        toast.error(err.customErrorMessage ?? "can not get logs")
      );
  };

  const getProductSizesLogs = async () => {
    return axios
      .get(APIs.products.sizes.logs(productId, 1281))
      .then((res) => {
        // setSizesLogs(res.data.data);
      })
      .catch((err) =>
        toast.error(err.customErrorMessage ?? "can not get logs")
      );
  };

  // const getLogs = () => {
  //   Promise.all([getProductLogs(), getProductSizesLogs()])
  //     .then(() => {
  //       setLoading(false);
  //     })
  //     .catch(() => {
  //       toast.error("Error loading data");
  //       setLoading(false);
  //     });
  // };

  useEffect(() => {
    getProductSizesLogs();
  }, []);

  return (
    <Layout
      pageTitle={`Logs of Order: ${productId}`}
      backButtonRoute={"/orders"}
    >
      <IonContent>
        {/* <section id="cd-timeline" className="cd-container">
          {_.map(logs, (log, index) => (
            <div key={index}>
              <div className="cd-timeline-block">
                <div className="cd-timeline-picture dark">
                  {log?.user?.avatar ? (
                    <img src={log?.user?.avatar} alt="avatar" />
                  ) : (
                    <span className="time-line-no-avatar">
                      {getFirstLetterOfName(log?.user?.name)}
                    </span>
                  )}
                </div>
                <div className="cd-timeline-content dark">
                  <h5>
                    {log?.user?.name}{" "}
                    <span className="timeline-user-type">
                      ({log?.user?.type})
                    </span>
                  </h5>
                  <p className="cd-author">
                    User{" "}
                    <span className="timeline-user-action">{log.status}</span>{" "}
                    the order.
                  </p>

                  <p className="timelineText">
                    {moment
                      .utc(log.created_at)
                      .local()
                      .format("DD/MM/YYYY HH:mm")}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </section> */}
      </IonContent>
    </Layout>
  );
};
