import { IStore } from "./Store";
import { ProductSizeType } from "./ProductSize";
import { IProduct } from "./Product";

export interface IOrderSize {
  id: number | string;
  count: number;
  code?: string;
  product?: IProduct;
  product_id?: number;
  price: number;
  price_with_vat?: number;
  type: ProductSizeType;
  pivot?: any;
  stock?: number;
  vat?: number;
}

export interface IOrderHalf {
  id: number | string;
  code?: string;
  product_id?: number;
  price: number;
  type: ProductSizeType;
  pivot?: any;
  stock?: number;
  vat?: number;
}

export interface IOrderLoose {
  id: string;
  title?: string;
  price: number | string;
  quantity: number;
  pivot?: any;
  created_at?: string;
  updated_at?: string;
  vat?: number | boolean;
}

export interface IOrderReturn {
  code: string;
  count: string;
  type: string;
}

export interface IOrder {
  id: string | number;
  store?: IStore;
  received_payment?: string | number;
  message?: string;
  sizes: Array<IOrderSize>;
  halves: Array<number>;
  halves_data?: Array<IOrderHalf>;
  returns: Array<IOrderReturn>;
  looses: Array<IOrderLoose>;
  status?: number;
  created_at?: string;
  start_at?: string;
  lastbalance?: string | number;
  count?: any;
  creator?: {
    name: string;
  };
  total_price?: string;
  payable_price?: string;
  attachment?: any;
  abbreviation?: string;
}

export enum ORDER_STATUS {
  draft = 0,
  pending = 1,
  complete = 2,
  reject = 3,
  deleted = 4,
}

export interface IOrderItem {
  id: string | number;
  received_payment?: string | number;
  message?: string;
  returns: number;
  status?: number;
  created_at?: string;
  start_at?: string;
  routerLink?: string;
  store_name?: string;
  description?: string;
  avatar?: string;
  order_status?: number;
  isLoader?: boolean;
  isDraft?: boolean;
  order?: IOrder;
  created_by?: number;
  name?: string;
  half_count?: number;
  loose_count?: number;
  small_count?: number;
  normal_count?: number;
  medium_count?: number;
  large_count?: number;
  returns_count?: number;
}
