// @ts-nocheck
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIs } from "../../../services/apiService";
import { OrderForm } from "../../../components/Reports/ProductsPrices/ProductPricesTable";
import { downloadOutline } from "ionicons/icons";
import { components } from "react-select";
import Layout from "../../../components/Layout/Layout";

export const ProductsStocks: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [type, setType] = useState<string>("normal");

  useEffect(() => {
    axios.get(APIs.order.productList).then((res) => {
      let data = res.data.data;
      data = _.chain(data)
        .groupBy("column_number")
        .map((val, key) => ({
          column_number: key,
          categories: val,
        }))
        .value();
      setData(data);
    });
  }, []);

  return (
    <Layout
      pageTitle={"Product Stocks"}
      containerClass="orders-page order-details-page"
      backButtonRoute={"/dashboard"}
      headerButtons={[
        {
          component: (
            <IonButton>
              <a
                className="download-link"
                type="text/csv"
                download="products-stock"
                href={APIs.reports.productsStockExport}
              >
                <IonIcon icon={downloadOutline} className="icon" />
              </a>
            </IonButton>
          ),
        },
      ]}
    >
      <IonContent>
        <IonButton onClick={() => setType("normal")}>Normal Size</IonButton>
        <IonButton onClick={() => setType("other")}>
          Small|Medium|Large Sizes
        </IonButton>
        <OrderForm productList={data} type={type} dataType="stock" />
      </IonContent>
    </Layout>
  );
};
