import { IUser } from "./User";

export interface IStore {
  id: string;
  name: string;
  description?: string;
  message?: string;
  active?: boolean | number;
  address: {
    location?: string;
  };
  image?: any;
  creator?: IUser;
  phone_number?: string | number;
  payment_term?: STORE_PAYMENT_TERM;
  visit_period?: string | number;
  ac?: string;
  colour?: string;
  discount?: number;
  message_status?: number;
  balance?: number;
}

export enum STORE_MESSAGE_STATUS {
  Always = 2,
  Enable = 1,
  Disable = 0,
}

export enum STORE_PAYMENT_TERM {
  CashOnDelivery = 1,
  Account = 2,
  oneInOneOut = 3,
}
