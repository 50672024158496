import React from "react";
import { IonButton } from "@ionic/react";
import "./WarehouseButton.scss";

type IButtonFillTypes = "default" | "clear" | "outline" | "solid" | undefined;
type IButtonSizes = "default" | "large" | "small" | undefined;

interface WarehouseButtonProps {
  onClickHandler: () => void;
  children?: any;
  text: string;
  fill?: IButtonFillTypes;
  size?: IButtonSizes;
  style?: any;
}

export const WarehouseButton: React.FC<WarehouseButtonProps> = ({
  onClickHandler,
  children,
  text,
  fill = "default",
  size = "large",
  style = {},
}) => {
  return (
    <IonButton
      className={`custom-button ${fill}`}
      fill={fill}
      onClick={onClickHandler}
      size={size}
      color="primary"
      style={{ height: "48px", ...style }}
    >
      {children && children}
      {text}
    </IonButton>
  );
};
