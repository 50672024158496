// @ts-nocheck
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { APIs } from "../../../../services/apiService";
import "./Shelving.scss";
import { AddCodeModal } from "./AddCodeModal/AddCodeModal";
import { WarehouseButton } from "../../WarehouseButton/WarehouseButton";
import Accordion from "../../../Accordion/Accordion";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import _ from "lodash";
import { UserItem } from "../../../User/UserItem/UserItem";

export const Shelving = (props: any) => {
  const [displayCodeModal, setDisplayCodeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState({
    complete: [],
    pending: [],
  });
  const [isInifinityLoadingDisabled, setIsInfinityLoadingDisabled] =
    useState(false);
  const [meta, setMeta] = useState<any>({
    current_page: 1,
    per_page: 10,
    last_page: 1,
    total: 0,
  });

  const getTasks = async (page: number) => {
    setLoading(true);
    return await axios
      .get(APIs["product-tasks"].index, {
        params: {
          per_page: meta.per_page,
          page,
        },
      })
      .then((res) => {
        const dataLength = res.data.data.length;
        if (!!dataLength) {
          let newTasks = {
            complete: page === 1 ? [] : [...tasks.complete],
            pending: page === 1 ? [] : [...tasks.pending],
          };
          res.data.data.forEach((task) => {
            if (task.status === 1) newTasks.complete.push(task);
            else newTasks.pending.push(task);
          });
          setTasks({
            ...newTasks,
          });
          setMeta({
            current_page: page,
            per_page: 10,
            last_page: 1,
            total: 0,
          });
        }
        if (dataLength < 10) setIsInfinityLoadingDisabled(true);
      })
      .catch((err) => {
        toast.error(
          err.customErrorMessage ?? "error in getting locations list"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUnload = (
    productSizeId: number,
    locationId: number,
    taskId: number
  ) => {
    axios
      .delete(APIs["product-size"].unLoad(productSizeId, locationId, taskId))
      .then((res) => {
        getTasks(1);
        setIsInfinityLoadingDisabled(false);
      })
      .catch((e) => {
        toast.success("something went wrong!");
      });
  };

  const doFetch = (event?: any) => {
    getTasks(meta.current_page + 1);
  };

  useEffect(() => {
    getTasks(meta.current_page);
  }, []);

  return (
    <div style={{ padding: 8 }}>
      <WarehouseButton
        onClickHandler={() => setDisplayCodeModal(true)}
        fill="solid"
        size="small"
        text="Add Code"
      />
      {/* <div style={{ padding: 8, fontWeight: 700 }}>Pending Tasks</div> */}
      {loading
        ? _.times(10, (i) => <UserItem key={i} isLoader={true} />)
        : !!tasks.pending.length && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                marginTop: 8,
              }}
            >
              {tasks.pending.map((task) => {
                const locations = task["product_size"]?.locations;
                const locationsLength = locations?.length;

                return (
                  <Accordion
                    summary={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          height: 48,
                          padding: "0 8px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            alignItems: "center",
                          }}
                        >
                          <div>{task["product_size"]?.product?.title}</div>
                          <div>{task["product_size"].code}</div>
                          <div>
                            {new Date(task.created_at).toLocaleDateString()}
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#ffc409",
                            padding: 8,
                            borderRadius: 8,
                            color: "black",
                          }}
                        >
                          {"pending"}
                        </div>
                      </div>
                    }
                    content={
                      !!locationsLength ? (
                        locations.map((location, index) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                padding: "4px 8px",
                                borderBottom:
                                  locationsLength > 1 &&
                                  index !== locationsLength - 1
                                    ? "1px solid gray"
                                    : "none",
                              }}
                              key={location.id}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 20,
                                }}
                              >
                                <div>Location: {location.title}</div>
                                <div>
                                  Load Date:{" "}
                                  {new Date(
                                    location.load_at
                                  ).toLocaleDateString()}
                                </div>
                              </div>

                              <button
                                onClick={() =>
                                  handleUnload(
                                    task["product_size"].id,
                                    location.id,
                                    task.id
                                  )
                                }
                                style={{
                                  color: "#980026",
                                  backgroundColor: "transparent",
                                  fontSize: "16px",
                                }}
                              >
                                Unload
                              </button>
                            </div>
                          );
                        })
                      ) : (
                        <div> not available in the warehouse </div>
                      )
                    }
                  />
                );
              })}
            </div>
          )}

      {!!tasks.complete.length && (
        <>
          <div style={{ padding: 8, fontWeight: 700, marginTop: 12 }}>
            Complete Tasks
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              marginTop: 8,
            }}
          >
            {tasks.complete.map((task) => {
              const locations = task["product_size"]?.locations;
              const locationsLength = locations?.length;

              return (
                <Accordion
                  summary={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        height: 48,
                        padding: "0 8px",
                        gap: "32px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "32px",
                          alignItems: "center",
                        }}
                      >
                        <div>{task["product_size"]?.product?.title}</div>
                        <div>{task["product_size"].code}</div>
                        <div>
                          {new Date(task.created_at).toLocaleDateString()}
                        </div>
                      </div>
                      <div
                        style={{
                          backgroundColor: "#2dd36f",
                          padding: 8,
                          borderRadius: 8,
                          color: "white",
                        }}
                      >
                        {"complete"}
                      </div>
                    </div>
                  }
                />
              );
            })}
          </div>
        </>
      )}

      <IonInfiniteScroll
        threshold="10px"
        onIonInfinite={doFetch}
        disabled={isInifinityLoadingDisabled}
      >
        <IonInfiniteScrollContent
          loadingSpinner="bubbles"
          loadingText="Loading more data..."
        />
      </IonInfiniteScroll>
      {displayCodeModal && (
        <AddCodeModal
          displayModal={displayCodeModal}
          toggleModal={() => {
            getTasks(1);
            setDisplayCodeModal(!displayCodeModal);
          }}
        />
      )}
    </div>
  );
};
