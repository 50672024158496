import React, { useEffect, useRef, useState } from "react";
import {
  IonButtons,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonRefresher,
  IonRefresherContent,
  IonAlert,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItemDivider,
  IonListHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonBackButton,
  IonFab,
  IonFabButton,
} from "@ionic/react";
import { addCircleOutline, pencilOutline, trashOutline } from "ionicons/icons";

import axios from "axios";
import _ from "lodash";
import { UserItem } from "../../components/User/UserItem/UserItem";
import { APIs } from "../../services/apiService";
import { IPaginationMeta } from "../../models/PaginationMeta";
import { toast } from "react-toastify";
import { AddLocationModal } from "../../components/Warehouse/AddLocationmodal/AddLocationModal";
import { WarehouseItem } from "../../components/Warehouse/WarehouseItem/WarehouseItem";
import { LoadShelvingModal } from "../../components/Warehouse/LoadShelvingModal/LoadShelvingModal";
import "./Warehouse.scss";
import { WarehouseButton } from "../../components/Warehouse/WarehouseButton/WarehouseButton";
import classNames from "classnames";

export const Warehouse: React.FC = () => {
  // TODO : change loading to content-loader instead of loading
  const [loading, setLoading] = useState(false);
  const [showDeleteLocationAlert, setShowDeleteLocationAlert] = useState(false);
  const [selectedLocationToDelete, setSelectedLocationToDelete] =
    useState<any>(null);
  const [isInifinityLoadingDisabled, setIsInfinityLoadingDisabled] =
    useState(false);
  const [displayLoadModal, setDisplayLoadModal] = useState(false);
  // TODO : implement search scenario
  const [searchLocation, setSearchLocation] = useState("");
  const [locations, setLocations] = useState<any[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    current_page: 1,
    per_page: 30,
    last_page: 1,
    total: 0,
  });

  const locationAddModalRef: any = useRef();
  const loadModalRef: any = useRef();

  const getLocations = async (page: number, type = "search") => {
    return await axios
      .get(APIs.locations.search, {
        params: {
          per_page: meta.per_page,
          page,
          search_key: searchLocation.length > 2 ? searchLocation : undefined,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        toast.error(
          err.customErrorMessage ?? "error in getting locations list"
        );
        return [];
      });
  };

  const addNewLocationToList = (location: any) => {
    // TODO : fix this code
    getInitialLocations();
    toast.success("Location has been saved");
  };

  const updateLocationInList = (location: any) => {
    // TODO : fix this code
    getInitialLocations();
    toast.success("Location has been saved");
  };

  const confirmDeleteLocation = (location: any) => {
    document.querySelector("ion-item-sliding")!.closeOpened();
    setShowDeleteLocationAlert(true);
    setSelectedLocationToDelete(location);
  };

  const deleteLocation = () => {
    setLoading(true);

    axios
      .delete(APIs.locations.delete(selectedLocationToDelete!.id))
      .then((res) => {
        doRefresh();
        setSelectedLocationToDelete(null);
        setShowDeleteLocationAlert(false);
        setLoading(false);
        toast.success("location deleted successfully");
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in deleting location");
      });
  };

  const editLocation = (location: any) => {
    document.querySelector("ion-item-sliding")!.closeOpened();
    locationAddModalRef.current.showEditModal(location);
  };

  const doFetch = async (event?: any) => {
    let data = await getLocations(meta.current_page + 1);

    setLocations(locations.concat(data.data!));
    setMeta(data.meta!);

    event.target.complete();

    if (locations.length >= meta.total) setIsInfinityLoadingDisabled(true);
  };

  const doRefresh = async (event?: any) => {
    setLoading(true);
    setLocations([]);
    setIsInfinityLoadingDisabled(false);

    let data = await getLocations(1, "show");
    setLocations(data.data);
    setMeta(data.meta!);
    setLoading(false);

    if (event) event.target.complete();
  };

  const showAddModal = () => {
    locationAddModalRef.current.showModal();
  };

  const getInitialLocations = async () => {
    setLoading(true);

    let data = await getLocations(1, "show");
    setLocations(data.data);
    setMeta(data.meta!);

    setLoading(false);
  };

  /**
   * life cycles and effects
   */
  useEffect(() => {
    getInitialLocations();
  }, []);

  useEffect(() => {
    if (searchLocation?.length > 0) {
      const searchLocations = async () => {
        setLoading(true);
        setMeta((prevMeta) => ({
          ...prevMeta,
          current_page: 1,
        }));

        let data = await getLocations(meta.current_page);

        setLocations(data.data);
        setMeta(data.meta!);
        setLoading(false);
      };

      searchLocations();
    }
  }, [searchLocation]);

  return (
    <IonPage className="ion-page">
      <IonHeader className={classNames("ion-no-border", "ion-header-desktop")}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/dashboard" />
          </IonButtons>

          <IonTitle className="ion-title">Warehouse</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonHeader className="ion-header-mobile">
        <IonToolbar className="ion-toolbar">
          <IonRow className="ion-row">
            <IonCol>
              <IonRow>
                <IonButtons style={{ height: "20px" }}>
                  <IonBackButton defaultHref="/dashboard" />
                </IonButtons>
                <IonTitle className="ion-title">Warehouse</IonTitle>
              </IonRow>
            </IonCol>

            <button onClick={() => setDisplayLoadModal(true)}>
              load/shelving
            </button>
          </IonRow>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-content">
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonCard className="ion-card">
          <IonCardHeader className="ion-card-header">
            <IonCardTitle>
              <IonButtons className="ion-buttons">
                <WarehouseButton
                  onClickHandler={() => setDisplayLoadModal(true)}
                  fill="outline"
                  text="Load / Shelving"
                />
                <WarehouseButton
                  onClickHandler={showAddModal}
                  text=""
                  fill="solid"
                  size="small"
                  style={{ width: "48px" }}
                >
                  <IonIcon icon={addCircleOutline}></IonIcon>
                </WarehouseButton>
              </IonButtons>
            </IonCardTitle>
          </IonCardHeader>
          <IonItemDivider className="ion-divider"></IonItemDivider>
          <IonCardContent className="ion-card-content">
            <IonList
              className={classNames("ion-list-wrapper", "ion-list-desktop")}
            >
              <IonListHeader className="ion-list-header">
                <IonGrid>
                  <IonRow>
                    <IonCol>Aisle</IonCol>
                    <IonCol>Bay</IonCol>
                    <IonCol>Level</IonCol>
                    <IonCol>Position</IonCol>
                  </IonRow>
                </IonGrid>
              </IonListHeader>
              {loading
                ? _.times(10, (i) => <UserItem key={i} isLoader={true} />)
                : locations?.map((location) => (
                    <WarehouseItem
                      key={location.id}
                      aisle={location.aisle}
                      bay={location.bay}
                      level={location.level}
                      position={location.position}
                      active={location.active}
                      onEditHandler={() => editLocation(location)}
                      onDeleteHandler={() => confirmDeleteLocation(location)}
                    />
                  ))}
            </IonList>

            <IonList
              className={classNames("ion-list-wrapper", "ion-list-mobile")}
            >
              {loading
                ? _.times(10, (i) => <UserItem key={i} isLoader={true} />)
                : locations?.map((location) => (
                    <IonItemSliding key={location.id}>
                      <WarehouseItem
                        aisle={location.aisle}
                        bay={location.bay}
                        level={location.level}
                        position={location.position}
                        active={location.active}
                        label={true}
                        className={classNames(
                          "custom-warehouse-item",
                          "warehouse-item-mobile"
                        )}
                      />
                      <IonItemOptions className="ion-item-options" side="end">
                        <IonItemOption
                          className={classNames(
                            "ion-item-option",
                            "option-edit"
                          )}
                          onClick={() => editLocation(location)}
                        >
                          <IonIcon
                            color="primary"
                            className="ion-option-icon"
                            icon={pencilOutline}
                          />
                        </IonItemOption>
                        <IonItemOption
                          className={classNames(
                            "ion-item-option",
                            "option-delete"
                          )}
                          onClick={() => confirmDeleteLocation(location)}
                        >
                          <IonIcon
                            className="ion-option-icon"
                            icon={trashOutline}
                          />
                        </IonItemOption>
                      </IonItemOptions>
                    </IonItemSliding>
                  ))}
            </IonList>

            <IonInfiniteScroll
              threshold="10px"
              onIonInfinite={doFetch}
              disabled={isInifinityLoadingDisabled}
            >
              <IonInfiniteScrollContent
                loadingSpinner="bubbles"
                loadingText="Loading more data..."
              />
            </IonInfiniteScroll>

            <AddLocationModal
              ref={locationAddModalRef}
              onLocationInsert={addNewLocationToList}
              onLocationUpdate={updateLocationInList}
            />

            {displayLoadModal && (
              <LoadShelvingModal
                displayModal={displayLoadModal}
                toggleModal={() => setDisplayLoadModal(!displayLoadModal)}
                locations={locations}
                setLocations={setLocations}
                getInitialLocations={getInitialLocations}
              />
            )}

            <IonAlert
              isOpen={showDeleteLocationAlert}
              onDidDismiss={() => setShowDeleteLocationAlert(false)}
              header={"Delete"}
              message={"Do you want to delete the location?"}
              buttons={[
                {
                  text: "Cancel",
                  role: "cancel",
                },
                {
                  text: "Delete",
                  handler() {
                    deleteLocation();
                  },
                },
              ]}
            />
          </IonCardContent>
        </IonCard>
      </IonContent>

      <IonFab
        className="ion-fab-mobile"
        vertical="bottom"
        horizontal="end"
        slot="fixed"
      >
        <IonFabButton
          className="ion-fab-button"
          color="primary"
          onClick={showAddModal}
        >
          <IonIcon className="ion-fab-icon" icon={addCircleOutline} />
        </IonFabButton>
      </IonFab>
    </IonPage>
  );
};
