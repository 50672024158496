import React from "react";
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import LogoImg from "../../assets/img/web-logo.png";

const Layout = ({
  containerClass = "orders-page",
  pageTitle,
  backButtonRoute,
  backButtonTitle,
  headerButtons = [],
  children,
}: {
  containerClass?: string;
  pageTitle?: string;
  backButtonRoute?: string | null;
  backButtonTitle?: string;
  headerButtons?: Array<{
    title: string;
    icon: React.ReactNode;
    component?: React.ReactNode;
    action?: any;
    noAccess?: boolean;
  }>;
  children: JSX.Element;
}) => {
  return (
    <IonPage className={containerClass}>
      <IonHeader>
        <IonToolbar>
          {backButtonRoute ? (
            <IonButtons slot="start">
              <IonBackButton
                defaultHref={backButtonRoute}
                text={backButtonTitle ?? "back"}
              />
            </IonButtons>
          ) : (
            <IonButtons slot="start">
              <IonMenuButton menu="main" autoHide={false} />
            </IonButtons>
          )}

          {backButtonRoute ? (
            <IonButtons slot="end" className="menu-container">
              {headerButtons.map(
                (btn) =>
                  !btn.noAccess &&
                  (btn.component ? (
                    btn.component
                  ) : (
                    <IonButton onClick={btn.action}>
                      {btn.title}
                      {btn.icon}
                    </IonButton>
                  ))
              )}
            </IonButtons>
          ) : (
            <div className="header-avatar-container" slot="end">
              <IonAvatar className="avatar">
                <img src={LogoImg} className="logo-img" alt="roynut" />
              </IonAvatar>
            </div>
          )}
          {pageTitle && (
            <IonTitle style={{ textAlign: "center" }}>{pageTitle}</IonTitle>
          )}
        </IonToolbar>
      </IonHeader>
      {children}
    </IonPage>
  );
};

export default Layout;
