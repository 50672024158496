import React, { useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  IonAlert,
  IonFab,
  IonFabButton,
  IonIcon,
  IonSearchbar,
} from "@ionic/react";
import {
  addOutline,
  hammerOutline,
  pencilOutline,
  trashOutline,
} from "ionicons/icons";

import "./Products.scss";

import { ProductAddModal } from "../../components/Product/ProductAddModal/ProductAddModal";
import { ProductsUpdateModal } from "../../components/Product/ProductsUpdateModal/ProductsUpdateModal";
import { IPaginationMeta } from "../../models/PaginationMeta";
import { IProduct } from "../../models/Product";
import axios from "axios";
import _ from "lodash";
import { APIs } from "../../services/apiService";
import { toast } from "react-toastify";
import { UserTypes } from "../../models/User";
import { useSelector } from "react-redux";
import { DailyProductModal } from "../../components/Product/DailyProductModal/DailyProductModal";
import { CustomList } from "../../components/List/List";
import Layout from "../../components/Layout/Layout";
// import {ProductSizeAddModal} from '../../components/ProductSizeAddModal/ProductSizeAddModal';

export const Products: React.FC = () => {
  const userType: any = useSelector<any>((state) => state.auth.user.type);
  const [loading, setLoading] = useState(false);
  const [displayProductsUpdateModal, setDisplayProductsUpdateModal] =
    useState(false);
  const [displayDailyProductsModal, setDisplayDailyProductsModal] =
    useState(false);

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selectedProudctToDelete, setSelectedProductToDelete] =
    useState<IProduct | null>(null);
  const [isInifinityLoadingDisabled, setIsInfinityLoadingDisabled] =
    useState(false);
  const [searchProduct, setSearchProduct] = useState("");
  const [products, setProducts] = useState<IProduct[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    current_page: 1,
    per_page: 30,
    last_page: 1,
    total: 0,
  });

  const productAddModalRef: any = useRef();

  const getProducts = async (page: number) => {
    return await axios
      .get(APIs.products.productList, {
        params: {
          per_page: meta.per_page,
          page,
          search_key: searchProduct.length > 0 ? searchProduct : undefined,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error getting products");
        return {
          data: [],
          meta: {},
        };
      });
  };

  const addNewProductToList = (product: IProduct) => {
    // TODO : fix this code
    if (UserTypes.stockController === userType) toast.info("Permission Denied");
    else {
      getInitialProducts();
      toast.success("Product has been saved.");
    }
  };

  const updateProductInList = (product: IProduct) => {
    // TODO : fix this code
    getInitialProducts();
    toast.success("Product has been saved.");
  };

  const confirmDeleteProduct = (product: IProduct) => {
    if (UserTypes.stockController === userType) toast.info("Permission Denied");
    else {
      document.querySelector("ion-item-sliding")!.closeOpened();
      setShowDeleteAlert(true);
      setSelectedProductToDelete(product);
    }
  };

  const deleteProduct = () => {
    setLoading(true);

    axios
      .delete(APIs.products.delete + selectedProudctToDelete!.id)
      .then(() => {
        doRefresh();
        setSelectedProductToDelete(null);
        setShowDeleteAlert(false);
        setLoading(false);
        toast.success("product deleted successfully");
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error deleting product");
        setLoading(false);
      });
  };

  const editProduct = (productId: string) => {
    document.querySelector("ion-item-sliding")!.closeOpened();
    productAddModalRef.current.showEditModal(productId);
  };

  const doFetch = async (event?: any) => {
    let data = await getProducts(meta.current_page + 1);

    setProducts(products.concat(data.data!));
    setMeta(data.meta!);

    event.target.complete();

    if (products.length >= meta.total) setIsInfinityLoadingDisabled(true);
  };

  const doRefresh = async (event?: any) => {
    setLoading(true);
    setProducts([]);
    setIsInfinityLoadingDisabled(false);

    let data = await getProducts(1);
    setProducts(data.data);
    setMeta(data.meta!);
    setLoading(false);

    if (event) event.target.complete();
  };

  const showAddModal = () => {
    productAddModalRef.current.showModal();
  };

  // const showSizeModal = (product: IProduct) => {
  //   document.querySelector('ion-item-sliding')!.closeOpened();
  //   productSizeAddModalRef.current.showModal(product.id);
  // }

  // const onSizesChange = () => {
  //   toast.success('Product has been saved.');
  // }

  const getInitialProducts = async () => {
    setLoading(true);

    let data = await getProducts(1);
    setProducts(data.data);
    setMeta(data.meta!);

    setLoading(false);
  };

  /**
   * life cycles
   */
  useEffect(() => {
    getInitialProducts();
  }, []);

  useEffect(() => {
    const searchProducts = async () => {
      setLoading(true);
      setMeta((prevMeta) => ({
        ...prevMeta,
        current_page: 1,
      }));

      let data = await getProducts(1);

      setProducts(data.data);
      setMeta(data.meta!);
      setLoading(false);
    };

    searchProducts();
  }, [searchProduct]);

  const columnList = [
    {
      coloumnName: "avatar",
      type: "avatar",
      label: "Avatar",
    },
    {
      coloumnName: "title",
      type: "string",
      label: "Name",
    },
    {
      coloumnName: "category.title",
      type: "string",
      label: "Category",
    },
    // {
    //   coloumnName: "updated_at",
    //   type: "date",
    //   label: "Last Update",
    // },
  ];

  const listActions = [
    {
      side: "start",
      options: [
        {
          title: "Edit",
          color: "secondary",
          actionType: "edit",
          roles: [
            UserTypes.admin,
            UserTypes.manager,
            UserTypes.saleRepresentative,
          ],
          actionFn: (item: any) => {
            editProduct(item.id);
          },
          icon: pencilOutline,
        },
      ],
    },
    {
      side: "end",
      options: [
        {
          title: "Delete",
          actionType: "delete",
          color: "danger",
          roles: [
            UserTypes.admin,
            UserTypes.manager,
            UserTypes.saleRepresentative,
          ],
          actionFn: (item: any) => {
            confirmDeleteProduct(item);
          },
          icon: trashOutline,
        },
      ],
    },
  ];

  return (
    <Layout
      pageTitle={"Products"}
      containerClass="orders-page"
      backButtonRoute={"/dashboard"}
      headerButtons={[
        {
          title: "Price",
          icon: "",
          action: () => setDisplayProductsUpdateModal(true),
          noAccess: ![UserTypes.admin, UserTypes.manager].includes(userType),
        },
        {
          title: "",
          icon: <IonIcon icon={hammerOutline} />,
          action: () => setDisplayDailyProductsModal(true),
          noAccess: ![
            UserTypes.admin,
            UserTypes.manager,
            UserTypes.stockController,
          ].includes(userType),
        },
      ]}
    >
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonSearchbar
          value={searchProduct}
          onIonChange={(e) => setSearchProduct(e.detail.value!)}
          autocomplete="on"
          debounce={2500}
        />

        <CustomList
          columnList={columnList}
          listActions={listActions}
          data={products}
          loading={loading}
        />
        {/* <IonList>
          {loading
            ? _.times(10, (i) => <ProductItem key={i} isLoader={true} />)
            : products.map((product, index) => (
                <IonItemSliding key={index}>
                  <ProductItem
                    name={product.title}
                    category={product.category.title}
                  />
                  <IonItemOptions side="start">
                    <IonItemOption
                      color="primary"
                      onClick={() => editProduct(product.id)}
                    >
                      Edit
                    </IonItemOption>
                  </IonItemOptions>
                  <IonItemOptions side="end">
                    {[UserTypes.admin, UserTypes.manager].includes(
                      userType
                    ) && (
                      <IonItemOption
                        color="danger"
                        onClick={() => confirmDeleteProduct(product)}
                      >
                        Delete
                      </IonItemOption>
                    )}
                  </IonItemOptions>
                </IonItemSliding>
              ))}
        </IonList> */}

        {/* Inifinite Scroll */}
        <IonInfiniteScroll
          threshold="10px"
          onIonInfinite={doFetch}
          disabled={isInifinityLoadingDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          />
        </IonInfiniteScroll>

        {/* Fab Button */}
        {[UserTypes.admin, UserTypes.manager].includes(userType) && (
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton color="danger" onClick={showAddModal}>
              <IonIcon icon={addOutline} />
            </IonFabButton>
          </IonFab>
        )}

        {/* Add/Edit Modal */}
        <ProductAddModal
          ref={productAddModalRef}
          onProductInsert={addNewProductToList}
          onProductUpdate={updateProductInList}
          userType={userType}
        />

        {/* Update Products Price Modal */}
        {displayProductsUpdateModal && (
          <ProductsUpdateModal
            displayModal={displayProductsUpdateModal}
            toggleModal={() =>
              setDisplayProductsUpdateModal(!displayProductsUpdateModal)
            }
          />
        )}

        {/* Update Products Stock Modal */}
        {/* {displayProductsStockModal && (
          <ProductsStockUpdateModal
            displayModal={displayProductsStockModal}
            toggleModal={() =>
              setDisplayProductsStockModal(!displayProductsStockModal)
            }
          />
        )} */}

        {/* Daily Products Modal */}
        {displayDailyProductsModal && (
          <DailyProductModal
            displayModal={displayDailyProductsModal}
            toggleModal={() =>
              setDisplayDailyProductsModal(!displayDailyProductsModal)
            }
          />
        )}

        {/* Delete alert */}
        <IonAlert
          isOpen={showDeleteAlert}
          onDidDismiss={() => setShowDeleteAlert(false)}
          header={"Delete"}
          message={"Do you want to delete the product?"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Delete",
              handler() {
                deleteProduct();
              },
            },
          ]}
        />
      </IonContent>
    </Layout>
  );
};
