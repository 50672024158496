import React, { useEffect, useRef, useState } from "react";
import {
  IonAlert,
  IonBackButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./Looses.scss";

import _ from "lodash";
import axios from "axios";
import { APIs } from "../../services/apiService";
import { ILoose } from "../../models/Loose";
import { addOutline, pencilOutline, trashOutline } from "ionicons/icons";
import { IPaginationMeta } from "../../models/PaginationMeta";
import { LooseItem } from "../../components/Loose/LooseItem/LooseItem";
import { LooseAddModal } from "../../components/Loose/LooseAddModal/LooseAddModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { UserTypes } from "../../models/User";
import { CustomList } from "../../components/List/List";
import Layout from "../../components/Layout/Layout";

export const Looses: React.FC = () => {
  const userType: any = useSelector<any>((state) => state.auth.user.type);
  const [loading, setLoading] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selectedLooseToDelete, setSelectedLooseToDelete] =
    useState<ILoose | null>(null);
  const [isInifinityLoadingDisabled, setIsInfinityLoadingDisabled] =
    useState(false);
  const [search, setSearch] = useState("");
  const [looses, setLooses] = useState<ILoose[]>([]);
  const [meta, setMeta] = useState<IPaginationMeta>({
    current_page: 1,
    per_page: 30,
    last_page: 1,
    total: 0,
  });

  const looseAddModalRef: any = useRef();

  const getLooses = async (page: number) => {
    return await axios
      .get(APIs.looses.index, {
        params: {
          per_page: meta.per_page,
          page,
          search_key: search.length > 2 ? search : undefined,
        },
      })
      .then((res) => res.data)
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in getting looses list");
        return {
          data: [],
          meta: {},
        };
      });
  };

  const addNewLooseToList = (loose: ILoose) => {
    // TODO : fix this code
    getInitialLooses();
    toast.success("Loose has been saved");
  };

  const updateLooseInList = (loose: ILoose) => {
    // TODO : fix this code
    getInitialLooses();
    toast.success("Loose has been saved");
  };

  const confirmDeleteLoose = (loose: ILoose) => {
    document.querySelector("ion-item-sliding")!.closeOpened();
    setShowDeleteAlert(true);
    setSelectedLooseToDelete(loose);
  };

  const deleteLoose = () => {
    setLoading(true);

    axios
      .delete(APIs.looses.delete(selectedLooseToDelete!.id))
      .then(() => {
        doRefresh();
        setSelectedLooseToDelete(null);
        setShowDeleteAlert(false);
        setLoading(false);
        toast.success("loose deleted successfully");
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in deleting loose");
        setLoading(false);
      });
  };

  const editLoose = (loose: ILoose) => {
    document.querySelector("ion-item-sliding")!.closeOpened();
    looseAddModalRef.current.showEditModal(loose);
  };

  const doFetch = async (event?: any) => {
    let data = await getLooses(meta.current_page + 1);

    setLooses(looses.concat(data.data!));
    setMeta(data.meta!);

    event.target.complete();

    if (looses.length >= meta.total) setIsInfinityLoadingDisabled(true);
  };

  const doRefresh = async (event?: any) => {
    setLoading(true);
    setLooses([]);
    setIsInfinityLoadingDisabled(false);

    let data = await getLooses(1);
    setLooses(data.data);
    setMeta(data.meta!);
    setLoading(false);

    if (event) event.target.complete();
  };

  const showAddModal = () => {
    looseAddModalRef.current.showModal();
  };

  const getInitialLooses = async () => {
    setLoading(true);

    let data = await getLooses(1);
    setLooses(data.data);
    setMeta(data.meta!);

    setLoading(false);
  };

  /**
   * life cycles
   */
  useEffect(() => {
    getInitialLooses();
  }, []);

  useEffect(() => {
    const searchLooses = async () => {
      setLoading(true);
      setMeta((prevMeta) => ({
        ...prevMeta,
        current_page: 1,
      }));

      let data = await getLooses(meta.current_page);

      setLooses(data.data);
      setMeta(data.meta!);
      setLoading(false);
    };

    searchLooses();
  }, [search]);

  const columnList = [
    {
      coloumnName: "avatar",
      type: "avatar",
      label: "Avatar",
    },
    {
      coloumnName: "title",
      type: "string",
      label: "Name",
    },
    {
      coloumnName: "price",
      type: "string",
      label: "Price",
    },
    // {
    //   coloumnName: "updated_at",
    //   type: "date",
    //   label: "Last Update",
    // },
  ];

  const listActions = [
    {
      side: "start",
      options: [
        {
          title: "Edit",
          color: "secondary",
          actionType: "edit",
          roles: [
            UserTypes.admin,
            UserTypes.manager,
            UserTypes.saleRepresentative,
          ],
          actionFn: (item: any) => {
            editLoose(item);
          },
          icon: pencilOutline,
        },
      ],
    },
    {
      side: "end",
      options: [
        {
          title: "Delete",
          actionType: "delete",
          color: "danger",
          roles: [
            UserTypes.admin,
            UserTypes.manager,
            UserTypes.saleRepresentative,
          ],
          actionFn: (item: any) => {
            confirmDeleteLoose(item);
          },
          icon: trashOutline,
        },
      ],
    },
  ];

  return (
    <Layout pageTitle={"Looses"} backButtonRoute={"/dashboard"}>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonSearchbar
          value={search}
          onIonChange={(e) => setSearch(e.detail.value!)}
          autocomplete="on"
          debounce={2500}
        />

        <CustomList
          columnList={columnList}
          listActions={listActions}
          data={looses}
          loading={loading}
        />

        {/* <IonList>
          {loading
            ? _.times(10, (i) => <LooseItem key={i} isLoader={true} />)
            : looses.map((loose, index) => (
                <IonItemSliding key={index}>
                  <LooseItem title={loose.title} price={loose.price} />
                  <IonItemOptions side="start">
                    <IonItemOption
                      color="primary"
                      onClick={() => editLoose(loose)}
                    >
                      Edit
                    </IonItemOption>
                  </IonItemOptions>
                  <IonItemOptions side="end">
                    {[UserTypes.admin, UserTypes.manager].includes(
                      userType
                    ) && (
                      <IonItemOption
                        color="danger"
                        onClick={() => confirmDeleteLoose(loose)}
                      >
                        Delete
                      </IonItemOption>
                    )}
                  </IonItemOptions>
                </IonItemSliding>
              ))}
        </IonList> */}

        {/* Infinite Scroll */}
        <IonInfiniteScroll
          threshold="10px"
          onIonInfinite={doFetch}
          disabled={isInifinityLoadingDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          />
        </IonInfiniteScroll>

        {/* Fab Button */}
        {[UserTypes.admin, UserTypes.manager].includes(userType) && (
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton color="danger" onClick={showAddModal}>
              <IonIcon icon={addOutline} />
            </IonFabButton>
          </IonFab>
        )}

        {/* Add/Edit Modal */}
        <LooseAddModal
          ref={looseAddModalRef}
          onLooseInsert={addNewLooseToList}
          onLooseUpdate={updateLooseInList}
          userType={userType}
        />

        {/* Delete alert */}
        <IonAlert
          isOpen={showDeleteAlert}
          onDidDismiss={() => setShowDeleteAlert(false)}
          header={"Delete"}
          message={"Do you want to delete the Loose?"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Delete",
              handler() {
                deleteLoose();
              },
            },
          ]}
        />
      </IonContent>
    </Layout>
  );
};
