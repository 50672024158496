import _ from "lodash";
import { PRODUCT_SIZES } from "../models/ProductSize";
import Resizer from "react-image-file-resizer";

const isObject = (objectToCheck: any) =>
  objectToCheck && objectToCheck.constructor === Object;

const dataUrlToFile = async (
  dataUrl: string,
  fileName: string
): Promise<File> => {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  // @ts-ignore
  return new File([blob], fileName, { type: "image/png" });
};

const resizeFile = (file: File) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1200,
      900,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export { isObject, resizeFile, dataUrlToFile };
