import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonLoading,
  IonCardSubtitle,
  IonTextarea,
  IonButton,
} from "@ionic/react";
import "./Ticket.scss";
import axios from "axios";
import _ from "lodash";
import { APIs } from "../../services/apiService";
import { ITicket } from "../../models/Ticket";
import { ITicketComment } from "../../models/TicketComment";
import { toast } from "react-toastify";
import moment from "moment";
import Layout from "../../components/Layout/Layout";

export const Ticket: React.FC = () => {
  const { id } = useParams();

  const [ticket, setTicket] = useState<ITicket>();
  const [comments, setComments] = useState<ITicketComment[]>([]);
  const [newComment, setNewComment] = useState("");
  const [loading, setLoading] = useState(false);

  const getTicket = async () => {
    try {
      setLoading(true);
      const fetchedTicket = await axios
        .get(APIs.tickets.show(id))
        .then((res) => res.data.data);
      const fetchedComments = await axios
        .get(APIs.tickets.comments.index(id))
        .then((res) => res.data.data);

      setTicket(fetchedTicket);
      setComments(fetchedComments);

      setLoading(false);
    } catch (e) {
      toast.error(e.customErrorMessage ?? "error in getting ticket data");
      setLoading(false);
    }
  };

  const saveNewComment = () => {
    setLoading(true);

    const newCommentRequestData = {
      comment: newComment,
    };

    axios
      .post(APIs.tickets.comments.store(id), newCommentRequestData)
      .then((res) => {
        setComments((prevState) => {
          prevState.push(res.data.data);
          return prevState;
        });
        setNewComment("");
      })
      .catch((err) => {
        toast.error(err.customErrorMessage ?? "error in saving comment");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTicket();
  }, []);

  return (
    <Layout
      pageTitle={"Ticket Details"}
      containerClass="ticket-page"
      backButtonRoute={"/tickets"}
    >
      <IonContent className="ion-padding">
        <IonCard className="ticket-card">
          <IonCardHeader>
            <IonCardTitle>{ticket?.title}</IonCardTitle>
            <IonCardSubtitle>
              {ticket?.to?.name}{" "}
              {ticket?.created_at
                ? " - " + moment(ticket?.created_at).format("ll")
                : ""}
            </IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>{ticket?.message}</IonCardContent>
        </IonCard>

        {/*Comments*/}
        {_.map(comments, (comment) => (
          <IonCard
            className={
              comment?.user?.id === ticket?.to.id
                ? "to-user-comment"
                : "from-user-comment"
            }
            key={comment?.id}
          >
            <IonCardHeader>
              <IonCardTitle>
                {comment?.user?.name + " "}
                <span className="comment-date">
                  (
                  {comment?.created_at
                    ? moment(comment.created_at).format("ll")
                    : ""}
                  )
                </span>
              </IonCardTitle>
            </IonCardHeader>

            <IonCardContent>{comment?.comment}</IonCardContent>
          </IonCard>
        ))}

        <IonTextarea
          className="comment-message"
          placeholder="enter your comment"
          value={newComment}
          onIonChange={(e: any) => setNewComment(e.target?.value)}
        ></IonTextarea>

        <IonButton
          className="save-comment-btn"
          color="primary"
          expand="full"
          onClick={saveNewComment}
        >
          Save Comment
        </IonButton>

        {/* Loading */}
        <IonLoading isOpen={loading} message={"please wait..."} />
      </IonContent>
    </Layout>
  );
};
