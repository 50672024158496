import React, { useState } from "react";
import "./Accordion.scss";

const Accordion = ({ summary, content }: { summary: any; content?: any }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div
        className="accordion-summary-container"
        onClick={() => setIsActive(!isActive)}
      >
        <div className="accordion-summary">{summary}</div>
        {!!content && (
          <div className="accordion-icon">{isActive ? "-" : "+"}</div>
        )}
      </div>
      {!!content && isActive && (
        <div className="accordion-content">{content}</div>
      )}
    </div>
  );
};

export default Accordion;
