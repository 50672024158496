// @ts-nocheck
import { IonItemOption, IonItemOptions, IonItemSliding } from "@ionic/react";
import React from "react";
import { OrderItem } from "../OrderItem/OrderItem";
import { UserTypes } from "../../../models/User";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { ORDER_STATUS } from "../../../models/Order";

const MobileOrderList = ({
  order,
  showDeleteOrderPopOver,
  showRollbackOrderPopOver,
  showDuplicateOrderPopOver,
}) => {
  const username: any = useSelector<any>((state) => state.auth.user.name);
  const userType: any = useSelector<any>((state) => state.auth.user.type);
  const userId: any = useSelector<any>((state) => state.auth.user.id);
  const location = useLocation();
  const history = useHistory();

  return (
    <IonItemSliding key={order.order.id}>
      <OrderItem
        routerLink={`/order/${order.order.id}`}
        {...order.order}
        isDraft={order.order.order_status == ORDER_STATUS.draft}
        username={username}
      />
      <IonItemOptions side="end">
        {[
          UserTypes.admin,
          UserTypes.manager,
          UserTypes.saleRepresentative,
        ].includes(userType) && (
          <>
            {(order.order.order_status == ORDER_STATUS.pending ||
              order.order.order_status == ORDER_STATUS.draft) && (
              <IonItemOption
                color="danger"
                onClick={() => showDeleteOrderPopOver(order.order, order.type)}
              >
                Delete
              </IonItemOption>
            )}

            {order.order.order_status == ORDER_STATUS.complete && (
              <IonItemOption
                color="secondary"
                onClick={() =>
                  showRollbackOrderPopOver(order.order, order.type)
                }
              >
                Rollback
              </IonItemOption>
            )}

            {order.type === "saved" && (
              <IonItemOption
                color="tertiary"
                onClick={() =>
                  showDuplicateOrderPopOver(order.order, order.type)
                }
              >
                Duplicate
              </IonItemOption>
            )}
          </>
        )}
      </IonItemOptions>
      <IonItemOptions side="start">
        {[
          UserTypes.admin,
          UserTypes.manager,
          UserTypes.saleRepresentative,
          UserTypes.picker,
        ].includes(userType) &&
          (order.order.status == ORDER_STATUS.pending ||
            order.order.status == ORDER_STATUS.draft) && (
            <IonItemOption
              routerLink={`/new-order/${order.order.id}`}
              color="primary"
            >
              Update
            </IonItemOption>
          )}
        {order.type === "saved" && (
          <IonItemOption
            routerLink={`/order/logs/${order.order.id}`}
            color="success"
          >
            Logs
          </IonItemOption>
        )}
      </IonItemOptions>
    </IonItemSliding>
  );
};

export default MobileOrderList;
